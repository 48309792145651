<template>
  <v-row no-gutters class="pa-5 fill-height align-center" justify="center">
    <v-col cols="4">
      <v-progress-linear
        :size="100"
        :width="5"
        :active="fetching"
        :indeterminate="fetching"
        color="#0297fe"
        rounded
        height="6"
      />
    </v-col>
    <v-card v-if="this.$store.state.active_result && file && file.result">
      <v-row no-gutters class="text-left fill-height">
        <v-col cols="12">
          <v-row no-gutters class="align-center">
            <v-col cols="8">
              <v-row
                class="text-h5 ml-4 mt-4"
                style="font-size: 1.2em !important"
              >
                <v-btn icon class="ml-4 mr-4" @click="$router.push('/')">
                  <v-icon>mdi-home</v-icon>
                </v-btn>
                <v-icon class="mr-2"> mdi-file </v-icon
                >{{ this.file.result.data.filename }}
              </v-row>
              <v-row
                v-show="this.file.result.data.callUid"
                class="text-caption ml-10 mt-6"
              >
                <v-icon class="mr-2"> mdi-identifier </v-icon
                >{{ this.file.result.data.callUid }}
              </v-row>
            </v-col>
            <v-col cols="4" class="pa-3 text-right">
              <v-row no-gutters>
                <v-btn
                  icon
                  fab
                  class="ml-2"
                  @click="deleteRow"
                  v-if="isUserAdmin() || isUserSuperAdmin()"
                  :disabled="
                    isDeletable(this.file.result.data.dateUpload.value)
                  "
                  :title="
                    isDeletable(this.file.result.data.dateUpload.value)
                      ? $i18n.t('TABLE.deletedAfter90')
                      : $i18n.t('ASSESSMENT.clickToDelete')
                  "
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn icon fab class="ml-2" @click="download()">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn icon fab class="ml-2" @click="downloadChat()">
                  <v-icon>mdi-comment-text</v-icon>
                </v-btn>
                <v-btn icon fab large @click="player.wavesurfer.skipBackward()">
                  <v-icon>mdi-skip-backward</v-icon>
                </v-btn>

                <v-btn icon fab x-large @click="togglePlayPause">
                  <v-icon
                    style="font-size: 56px !important"
                    v-if="!player.status"
                    x-large
                  >
                    mdi-play
                  </v-icon>
                  <v-icon style="font-size: 56px !important" v-else>
                    mdi-pause
                  </v-icon>
                </v-btn>

                <v-btn icon fab large @click="player.wavesurfer.skipForward()">
                  <v-icon>mdi-skip-forward</v-icon>
                </v-btn>
                <v-autocomplete
                  style="width: 60px; display: inline-block"
                  v-model="speed"
                  :items="speeds"
                  :label="$i18n.t('ASSESSMENT.SPEED')"
                  @change="updateSpeed"
                  class="ml-2"
                />
              </v-row>
            </v-col>
            <v-col cols="8">
              <v-subheader>
                {{ file.result.data.summarization }}
              </v-subheader>
            </v-col>
            <v-col cols="4">
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="playnoblanks"
                    class="ml-2"
                    :label="$i18n.t('ASSESSMENT.AVOIDBLANK')"
                  />
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="onlyPlayNegatives"
                    class="ml-2"
                    :label="$i18n.t('ASSESSMENT.NEGATIVE')"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <div id="player" />
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            color="#0297fe"
          />
          <v-divider class="mt-4" />
        </v-col>

        <v-col cols="12">
          <v-row no-gutters>
            <v-col
              cols="5"
              style="overflow-x: hidden; overflow-y: scroll; max-height: 400px"
            >
              <v-item-group active-class="primary">
                <v-container>
                  <v-row
                    v-for="(utterance, index) in utterances"
                    :key="index"
                    :style="getConversationStyle(utterance.speaker)"
                    :class="[
                      {
                        scrollInto:
                          currentTime >= utterance.start &&
                          currentTime <= utterance.end
                            ? scrollInto(index)
                            : false,
                      },
                      elemClass,
                    ]"
                    @click="setCurrentTime(utterance.start)"
                  >
                    <v-avatar
                      :color="returnColorSentiment(utterance.sentiment)"
                      size="36"
                      style="margin-right: 10px"
                      v-if="utterance.speaker == 'Agent'"
                    >
                      <v-icon color="white"> mdi-headset </v-icon>
                    </v-avatar>

                    <v-card
                      class="mx-auto"
                      max-width="400"
                      style="margin: 0 !important"
                    >
                      <v-card-text style="padding-bottom: 0 !important">
                        <template v-for="word in utterance.words">
                          <span
                            :key="word.start"
                            :class="{
                              activeWord:
                                currentTime >= word.start &&
                                currentTime <= word.end,
                              keyword: isAKeyword(
                                word.punctuated_word.toLowerCase(),
                                JSON.parse(file.result.data.keywords),
                                utterance.transcript.toLowerCase()
                              ),
                            }"
                            :style="getWordSentiment(word.sentiment)"
                          >
                            {{ word.punctuated_word }}
                          </span>
                        </template>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer
                          v-if="utterance.speaker == 'Agent'"
                        ></v-spacer>
                        <span class="mr-2 text-caption grey--text">
                          {{
                            new Date(utterance.start * 1000)
                              .toISOString()
                              .substr(11, 8)
                          }}
                        </span>
                      </v-card-actions>
                    </v-card>

                    <v-avatar
                      :color="returnColorSentiment(utterance.sentiment)"
                      size="36"
                      style="margin-left: 10px"
                      v-if="utterance.speaker !== 'Agent'"
                    >
                      <v-icon color="white"> mdi-account </v-icon>
                    </v-avatar>
                  </v-row>
                </v-container>
              </v-item-group>
            </v-col>
            <v-col cols="7">
              <v-tabs
                v-model="tab"
                background-color="transparent"
                color="basil"
                grow
              >
                <v-tab> {{ $i18n.t("ASSESSMENT.OBSERVATIONS") }} </v-tab>
                <v-tab> {{ $i18n.t("ASSESSMENT.METRICS") }} </v-tab>
                <v-tab> {{ $i18n.t("ASSESSMENT.SENTIMENT") }} </v-tab>
                <v-tab> {{ $i18n.t("ASSESSMENT.TOPICS") }} </v-tab>
                <v-tab> {{ $i18n.t("ASSESSMENT.EMAIL") }} </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab" v-if="file">
                <v-tab-item
                  style="
                    max-height: 354px;
                    overflow-y: scroll;
                    overflow-x: hidden;
                  "
                >
                  <v-btn
                    v-if="player.regions.length > 0"
                    dark
                    x-small
                    elevation="1"
                    color="green"
                    style="
                      position: absolute;
                      right: 10px;
                      bottom: 5px;
                      z-index: 4;
                    "
                    fab
                    @click="saveRegions"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <template
                    v-if="
                      player &&
                      player.wavesurfer &&
                      player.wavesurfer.regions &&
                      player.wavesurfer.regions.list &&
                      Object.keys(player.wavesurfer.regions.list).length > 0 &&
                      checkRegionsDrag()
                    "
                  >
                    <v-card
                      v-show="region.drag"
                      v-for="region in player.regions"
                      :key="region.id"
                      :color="
                        player.regionClicked &&
                        player.regionClicked.id == region.id
                          ? 'yellow lighten-5'
                          : 'transparent'
                      "
                      elevation="0"
                      :id="region.id + '-comment'"
                      style="transition: all 400ms ease-in"
                    >
                      <v-row class="pa-2 align-center" no-gutters>
                        <v-col cols="2">
                          <v-btn text disabled>
                            {{ region.start.toFixed(1) }}-{{
                              region.end.toFixed(1)
                            }}
                          </v-btn>
                        </v-col>
                        <v-col cols="9">
                          <v-textarea
                            rows="1"
                            :label="$i18n.t('ASSESSMENT.COMMENT')"
                            v-model="region.comment"
                          />
                        </v-col>
                        <v-col cols="1">
                          <v-btn icon @click="setCurrentTime(region.start)">
                            <v-icon>mdi-play</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </template>

                  <v-card v-else class="text-center align-center" elevation="0">
                    <v-icon color="grey lighten-1" style="margin-top: 85px">
                      mdi-message
                    </v-icon>
                    <span
                      class="text-h5"
                      style="display: block; margin-top: 30px"
                    >
                      {{ $i18n.t("ASSESSMENT.NOOBSERV") }}</span
                    >
                    <span class="mt-5" style="display: block">{{
                      $i18n.t("ASSESSMENT.NOOBSERVCOMMENT")
                    }}</span>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-row
                    no-gutters
                    style="
                      max-height: 354px;
                      overflow-y: scroll;
                      overflow-x: hidden;
                    "
                  >
                    <v-col cols="4">
                      <PlotMetrics
                        :metrics="
                          file.result.data.metrics.talkTimeMetrics != undefined
                            ? file.result.data.metrics.talkTimeMetrics
                            : []
                        "
                        :type="'talktime'"
                      />
                    </v-col>
                    <v-col cols="4">
                      <PlotMetrics
                        :metrics="
                          file.result.data.metrics.overtalkMetrics != undefined
                            ? file.result.data.metrics.overtalkMetrics
                            : []
                        "
                        :type="'overtalk'"
                      />
                    </v-col>
                    <v-col cols="4">
                      <PlotMetrics
                        :metrics="
                          file.result.data.metrics.wordsMetrics != undefined
                            ? file.result.data.metrics.wordsMetrics
                            : []
                        "
                        :type="'words'"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="
                          getNoTalkTimePercentage(
                            file.result.data.metrics.talkTimeMetrics,
                            file.result.data.callDuration
                          )
                        "
                        hide-default-footer
                      >
                        <template v-slot:item.metricValue="{ item }">
                          {{ item.metricValue }} %
                        </template>
                      </v-data-table>
                    </v-col>

                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="file.result.data.metrics.talkTimeMetrics"
                        hide-default-footer
                      >
                        <template v-slot:item.metricValue="{ item }">
                          {{ item.metricValue.toFixed(2) }}
                        </template>
                      </v-data-table>
                    </v-col>

                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="file.result.data.metrics.overtalkMetrics"
                        hide-default-footer
                      >
                        <template v-slot:item.metricValue="{ item }">
                          {{ item.metricValue.toFixed(2) }}
                        </template>
                      </v-data-table>
                    </v-col>

                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="file.result.data.metrics.wordsMetrics"
                        hide-default-footer
                      >
                        <template v-slot:item.metricValue="{ item }">
                          {{ item.metricValue.toFixed(2) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item>
                  <v-row
                    class="text-left"
                    no-gutters
                    style="
                      max-height: 354px;
                      overflow-y: scroll;
                      overflow-x: hidden;
                    "
                  >
                    <v-col cols="3">
                      <v-card
                        class="pa-4 ma-4"
                        v-if="
                          file.result.data.metrics.sentimentMetrics.filter(
                            (elem) => elem.metricName.includes('agent')
                          ).length != 0
                        "
                      >
                        <v-card-title>
                          {{
                            file.result.data.metrics.sentimentMetrics.filter(
                              (elem) =>
                                elem.metricName == "agent-start-sentiment"
                            )[0].metricDescription
                          }}
                        </v-card-title>
                        <v-card-text>
                          {{
                            file.result.data.metrics.sentimentMetrics.filter(
                              (elem) =>
                                elem.metricName == "agent-start-sentiment"
                            )[0].metricValue
                          }}
                        </v-card-text>
                      </v-card>
                      <v-card class="pa-4 ma-4" v-else>
                        <v-card-title>Agent start</v-card-title>
                        <v-card-text>No Data</v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="3">
                      <v-card
                        class="pa-4 ma-4"
                        v-if="
                          file.result.data.metrics.sentimentMetrics.filter(
                            (elem) => elem.metricName.includes('agent')
                          ).length != 0
                        "
                      >
                        <v-card-title>
                          {{
                            file.result.data.metrics.sentimentMetrics.filter(
                              (elem) => elem.metricName == "agent-end-sentiment"
                            )[0].metricDescription
                          }}
                        </v-card-title>
                        <v-card-text>
                          {{
                            file.result.data.metrics.sentimentMetrics.filter(
                              (elem) => elem.metricName == "agent-end-sentiment"
                            )[0].metricValue
                          }}
                        </v-card-text>
                      </v-card>
                      <v-card class="pa-4 ma-4" v-else>
                        <v-card-title>
                          {{ $i18n.t("ASSESSMENT.agentEnd") }}
                        </v-card-title>
                        <v-card-text>
                          {{ $i18n.t("ASSESSMENT.noData") }}
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="3">
                      <v-card
                        class="pa-4 ma-4"
                        v-if="
                          file.result.data.metrics.sentimentMetrics.filter(
                            (elem) => elem.metricName.includes('caller')
                          ).length != 0
                        "
                      >
                        <v-card-title>
                          {{
                            file.result.data.metrics.sentimentMetrics.filter(
                              (elem) =>
                                elem.metricName == "caller-start-sentiment"
                            )[0].metricDescription
                          }}
                        </v-card-title>
                        <v-card-text>
                          {{
                            file.result.data.metrics.sentimentMetrics.filter(
                              (elem) =>
                                elem.metricName == "caller-start-sentiment"
                            )[0].metricValue
                          }}
                        </v-card-text>
                      </v-card>
                      <v-card class="pa-4 ma-4" v-else>
                        <v-card-title>
                          {{ $i18n.t("ASSESSMENT.callerStart") }}
                        </v-card-title>
                        <v-card-text>
                          {{ $i18n.t("ASSESSMENT.noData") }}
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="3">
                      <v-card
                        class="pa-4 ma-4"
                        v-if="
                          file.result.data.metrics.sentimentMetrics.filter(
                            (elem) => elem.metricName.includes('caller')
                          ).length != 0
                        "
                      >
                        <v-card-title>
                          {{
                            file.result.data.metrics.sentimentMetrics.filter(
                              (elem) =>
                                elem.metricName == "caller-end-sentiment"
                            )[0].metricDescription
                          }}
                        </v-card-title>
                        <v-card-text>
                          {{
                            file.result.data.metrics.sentimentMetrics.filter(
                              (elem) =>
                                elem.metricName == "caller-end-sentiment"
                            )[0].metricValue
                          }}
                        </v-card-text>
                      </v-card>
                      <v-card class="pa-4 ma-4" v-else>
                        <v-card-title>
                          {{ $i18n.t("ASSESSMENT.callerEnd") }}
                        </v-card-title>
                        <v-card-text>
                          {{ $i18n.t("ASSESSMENT.noData") }}
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <PlotMetrics
                        :metrics="
                          file.result.data.metrics.sentimentMetrics != undefined
                            ? file.result.data.metrics.sentimentMetrics
                            : []
                        "
                        :type="'sentiment'"
                      />
                      <v-subheader>
                        {{ $i18n.t("ASSESSMENT.voiceTone") }}
                      </v-subheader>
                      <PlotMetrics
                        :metrics="
                          file.result.data.metrics.voiceToneMetrics != undefined
                            ? file.result.data.metrics.voiceToneMetrics
                            : []
                        "
                        :type="'voicesentiment'"
                      />

                      <v-row>
                        <v-col cols="6">
                          <v-card
                            class="pa-4 ma-4"
                            v-if="
                              file.result.data.metrics.voiceToneMetrics.filter(
                                (elem) => elem.metricName.includes('agent')
                              ).length != 0
                            "
                          >
                            <v-card-title>
                              {{
                                file.result.data.metrics.voiceToneMetrics.filter(
                                  (elem) => elem.metricName == "agent-sentiment"
                                )[0].metricDescription
                              }}
                            </v-card-title>
                            <v-card-text>
                              {{
                                file.result.data.metrics.voiceToneMetrics
                                  .filter(
                                    (elem) =>
                                      elem.metricName == "agent-sentiment"
                                  )[0]
                                  .metricValue.toFixed(2)
                              }}
                            </v-card-text>
                          </v-card>
                          <v-card class="pa-4 ma-4" v-else>
                            <v-card-title>
                              {{
                                $i18n.t("ASSESSMENT.averageSentimentScoreAgent")
                              }}
                            </v-card-title>
                            <v-card-text>
                              {{ $i18n.t("ASSESSMENT.noData") }}
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="6">
                          <v-card
                            class="pa-4 ma-4"
                            v-if="
                              file.result.data.metrics.voiceToneMetrics.filter(
                                (elem) => elem.metricName.includes('caller')
                              ).length != 0
                            "
                          >
                            <v-card-title>
                              {{
                                file.result.data.metrics.voiceToneMetrics.filter(
                                  (elem) =>
                                    elem.metricName == "caller-sentiment"
                                )[0].metricDescription
                              }}
                            </v-card-title>
                            <v-card-text>
                              {{
                                file.result.data.metrics.voiceToneMetrics
                                  .filter(
                                    (elem) =>
                                      elem.metricName == "caller-sentiment"
                                  )[0]
                                  .metricValue.toFixed(2)
                              }}
                            </v-card-text>
                          </v-card>
                          <v-card class="pa-4 ma-4" v-else>
                            <v-card-title>
                              {{
                                $i18n.t(
                                  "ASSESSMENT.averageSentimentScoreCaller"
                                )
                              }}
                            </v-card-title>
                            <v-card-text>
                              {{ $i18n.t("ASSESSMENT.noData") }}
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-card
                    v-if="
                      file.result.data.topics &&
                      file.result.data.topics.length > 0
                    "
                    style="
                      max-height: 354px;
                      overflow-y: scroll;
                      overflow-x: hidden;
                    "
                  >
                    <v-card-text>
                      <v-chip-group column>
                        <v-chip
                          v-for="topic in filterRepeatedTopics"
                          color="primary"
                          text-color="white"
                          :key="topic.name"
                        >
                          {{ topic.topic }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </v-card>
                  <v-card
                    v-if="
                      file.result.data.topics &&
                      file.result.data.topics.length == 0
                    "
                    elevation="0"
                  >
                    <v-alert type="info" elevation="0" colored-border>
                      {{ $i18n.t("ASSESSMENT.noTopicsDetected") }}
                    </v-alert>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card
                    v-if="
                      file.result.data.follow_up_email &&
                      file.result.data.follow_up_email.length > 0
                    "
                    elevation="0"
                  >
                    <v-card-text style="white-space: pre-line">
                      <span class="mb-6">{{
                        file.result.data.follow_up_email
                      }}</span>
                    </v-card-text>
                  </v-card>
                  <v-card v-else>
                    <v-card-text>
                      <span class="mb-6">
                        {{ $i18n.t("ASSESSMENT.noFollowEmail") }}
                      </span>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import moment from "moment";
import Transcription from "./../models/transcription.js";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";

import PlotMetrics from "@/components/PlotMetrics.vue";

import WaveSurfer from "wavesurfer.js";

import { getStorage, ref, getDownloadURL } from "firebase/storage";
import useDeleteCalls from "../hooks/useDeleteCalls";
const RegionsPlugin = require("wavesurfer.js/dist/plugin/wavesurfer.regions.min.js");
const MarkersPlugin = require("wavesurfer.js/dist/plugin/wavesurfer.markers.min.js");
const { returnCredibleKeywords } = require("@/utils/keywords");

export default {
  name: "RecordingPanel",
  components: {
    PlotMetrics,
  },
  data: () => ({
    fetching: false,
    playnoblanks: false,
    onlyPlayNegatives: false,
    mute: false,
    volume: 1,
    speed: 1,
    speeds: [0.5, 0.75, 1, 1.5, 2],
    loading: true,
    elemClass: "",
    currentTime: null,
    utterances: [],
    sentiment: {
      agent: [],
    },
    player: {
      regions: [],
      wavesurfer: {
        regions: {},
      },
      regionClicked: null,
      status: false,
    },
    tab: 0,
    file: null,
  }),
  methods: {
    async getRegions() {
      const db = getFirestore();

      const docRef = doc(
        db,
        `companies/${
          this.$store.state.Auth.token.claims.user_id.split("@")[1]
        }/users/${this.file.result.data.userid}/${moment(
          this.file.result.data.dateUpload.value
        ).format("YYYYMMDD")}`,
        this.file.result.data.uid
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data().observations;
      } else {
        return [];
      }
    },
    async saveRegions() {
      const regions = this.player.regions;
      const arrRegions = [];
      if (regions.length > 0) {
        regions.forEach((region) => {
          if (region.drag) {
            arrRegions.push({
              start: region.start,
              end: region.end,
              comment: region.comment || "",
            });
          }
        });

        try {
          const db = getFirestore();
          const docRef = doc(
            db,
            `companies/${
              this.$store.state.Auth.token.claims.user_id.split("@")[1]
            }/users/${this.file.result.data.userid}/${moment(
              this.file.result.data.dateUpload.value
            ).format("YYYYMMDD")}`,
            this.file.result.data.uid
          );

          await updateDoc(docRef, {
            observations: arrRegions,
          });

          this.$store.commit("update_result_regions", arrRegions);

          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "Success",
            text: "Observations updated",
          });
        } catch (e) {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "Error",
            text: e.message,
          });
        }
      }
    },
    checkRegionsDrag() {
      let drag;
      for (const key in this.player.wavesurfer.regions.list) {
        this.player.wavesurfer.regions.list[key].drag
          ? (drag = true)
          : (drag = false);
      }

      return drag;
    },
    getSorted() {
      if (this.player.regions.length > 0) {
        this.player.regions = this.player.regions.sort(
          (a, b) => a.start - b.start
        );
      }
    },
    download() {
      fetch(this.file.url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = this.file.result.data.filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((e) => console.warn(e));
    },
    setCurrentTime(s) {
      this.player.wavesurfer.play(s);
      this.player.status = true;
    },
    scrollInto(index) {
      const elems = document.getElementsByClassName(this.elemClass);
      elems[index].scrollIntoView({ behavior: "smooth", block: "nearest" });
    },
    returnColorSentiment(sentiment) {
      const color = "primary";

      if (sentiment === "positive") {
        return "green";
      }
      if (sentiment === "negative") {
        return "red";
      }

      return color;
    },
    // a method that returns the color based on the sentiment
    returnColor(sentiment) {
      const color = "none";

      if (sentiment === "positive") {
        return "#43a04738";
      }
      if (sentiment === "negative") {
        return "#e5393536";
      }

      return color;
    },
    async getURL() {
      const selectedDate = moment(this.file.result.data.dateUpload.value);
      const selectedDateUtc = selectedDate.clone().utc();
      const filePath =
        `audio-files/${this.file.result.data.company}/${selectedDateUtc.format(
          "YYYYMMDD"
        )}/${this.file.result.data.userid}/` + this.file.result.data.filename;
      const storage = getStorage();
      const spaceRef = ref(storage, filePath);

      const result = await getDownloadURL(spaceRef);
      return result;
    },
    // a method that iterates caller and agent utterances creatting a string like a chat and downloads it as a txt file.
    downloadChat() {
      let chat = "";
      const utterances = this.utterances;
      const agent = utterances.filter((elem) => elem.speaker === "Agent");
      const caller = utterances.filter((elem) => elem.speaker === "Caller");

      let i = 0;
      let j = 0;
      while (i < agent.length || j < caller.length) {
        if (agent[i] && caller[j]) {
          if (agent[i].start < caller[j].start) {
            chat += `${new Date(agent[i].start * 1000)
              .toISOString()
              .substr(11, 8)} - Agent: ${agent[i].transcript} \n`;
            i++;
          } else {
            chat += `${new Date(caller[j].start * 1000)
              .toISOString()
              .substr(11, 8)} - Caller: ${caller[j].transcript} \n`;
            j++;
          }
        } else if (agent[i]) {
          chat += `${new Date(agent[i].start * 1000)
            .toISOString()
            .substr(11, 8)} - Agent: ${agent[i].transcript} \n`;
          i++;
        } else if (caller[j]) {
          chat += `${new Date(caller[j].start * 1000)
            .toISOString()
            .substr(11, 8)} - Caller: ${caller[j].transcript} \n`;
          j++;
        }
      }

      const blob = new Blob([chat], { type: "text/plain;charset=utf-8" });
      this.saveAs(blob, "chat.txt");
    },
    // a method that receives a blob and a file name and downloads it.
    saveAs(blob, fileName) {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link);
    },
    // a method that receives a play speed and updates wavesurfer play speed.
    updateSpeed() {
      this.player.wavesurfer.setPlaybackRate(this.speed);
    },
    // a method that receives a volume and updates wavesurfer volume.
    updateVolume() {
      this.player.wavesurfer.setVolume(this.volume);
    },
    // a method that receives a boolean and updates wavesurfer mute.
    updateMute() {
      this.player.wavesurfer.setMute(this.mute);
    },
    // a method that receives an object and returns an array with the object values. */
    objectToArray(obj) {
      return Object.keys(obj).map((key) => obj[key]);
    },
    togglePlayPause() {
      this.player.status
        ? this.player.wavesurfer.pause()
        : this.player.wavesurfer.play();
      this.player.status = !this.player.status;

      if (this.player.status && this.onlyPlayNegatives) {
        // here it should get the current play time and look for the next negative utterance if its not already inside a negative an play it.
        const currentTime = this.player.wavesurfer.getCurrentTime();
        const negativeUtterances = this.utterances.filter(
          (elem) => elem.sentiment === "negative"
        );
        const nextNegativeUtterance = negativeUtterances.find(
          (elem) => elem.start > currentTime
        );

        if (nextNegativeUtterance) {
          this.player.wavesurfer.play(nextNegativeUtterance.start);
        }
      }
      if (this.player.status && this.playnoblanks) {
        const currentTime = this.player.wavesurfer.getCurrentTime();
        const noBlankUtterances = this.utterances.filter(
          (elem) => elem.transcript !== ""
        );
        const nextNoBlankUtterance = noBlankUtterances.find(
          (elem) => elem.start > currentTime
        );

        if (nextNoBlankUtterance) {
          this.player.wavesurfer.play(nextNoBlankUtterance.start);
        }
      }
    },
    async deleteRow() {
      const deleteCall = useDeleteCalls();
      const { uid, company, userid, dateUpload, filename } =
        this.file.result.data;
      const callData = {
        uid,
        company,
        userId: userid,
        date: dateUpload.value,
        filename,
      };
      await deleteCall([callData], this.$router);
    },
    isUserAdmin() {
      return this.$store.state.Auth.token.role === "admin";
    },
    isUserSuperAdmin() {
      return this.$store.state.Auth.token.role === "superuser";
    },
    isDeletable(dateUploaded) {
      const now = moment().toDate();
      const deletableTime = moment(dateUploaded).add(90, "m").toDate();
      return !(now >= deletableTime);
    },
    getConversationStyle(speaker) {
      const defaultStyles = {
        display: "flex",
        alignItems: "flexEnd",
        margin: "10px 5px 0 5px",
      };
      if (speaker !== "Agent") {
        return {
          ...defaultStyles,
          justifyContent: "right",
        };
      }

      return {
        ...defaultStyles,
        justifyContent: "left",
      };
    },
    getWordSentiment(sentiment) {
      if (sentiment === "positive") {
        return {
          color: "green",
        };
      }

      if (sentiment === "negative") {
        return {
          color: "red",
        };
      }

      return {};
    },
    getNoTalkTimePercentage(metrics, callDuration) {
      const metricPercentage = metrics.filter(
        (elem) => elem.metricName === "no-talk-time-percentage"
      );
      if (metricPercentage.length) return metricPercentage;

      const metric = metrics.filter(
        (elem) => elem.metricName === "no-talk-time"
      );
      return [
        {
          ...metric[0],
          metricValue: ((metric[0].metricValue / callDuration) * 100).toFixed(
            2
          ),
        },
      ];
    },
    isAKeyword(word, keywords, transcript) {
      if (keywords?.length) {
        const credibleKeywords = returnCredibleKeywords(keywords);

        const isPartOfKeyword = credibleKeywords.some(
          ({ query: keyword }) => keyword.indexOf(word) !== -1
        );
        if (isPartOfKeyword) {
          const isFullWordOnTranscript = credibleKeywords.some(
            ({ query: keyword }) => transcript.indexOf(keyword) !== -1
          );
          if (isFullWordOnTranscript) return true;
        }

        return credibleKeywords.includes(word);
      }
      return false;
    },
  },
  async created() {
    this.fetching = true;
    const response = await Transcription.getResultById(
      moment(this.$route.params.date).format(),
      this.$route.params.user,
      this.$route.params.uid
    );
    this.fetching = false;
    if (response && response.length > 0) {
      const result = response[0].row;
      result.metrics = JSON.parse(result.metrics);
      result.utterances = JSON.parse(result.utterances);
      result.topics = JSON.parse(result.topics);
      this.$store.commit("OPEN_RESULT", result);

      this.elemClass = 0 + "-message-timeline";

      const file = {
        result: {
          data: JSON.parse(JSON.stringify(result)),
        },
      };

      this.file = file;
      this.utterances = JSON.parse(JSON.stringify(file.result.data.utterances));
      const markers = [];

      const regions = file.result.data.utterances.map((elem) => {
        const speaker = elem.speaker;
        const sentiment = elem.sentiment;

        let color;

        if (sentiment === "positive") {
          color = "#43a04738";
        } else if (sentiment === "negative") {
          color = "#e5393536";
        } else {
          color = "rgb(181 181 181 / 32%)";
        }

        markers.push({
          time: elem.start,
          label: sentiment === "negative" ? "!" : "",
          color:
            speaker === "Agent" || speaker === "Speaker1"
              ? "#ff98009e"
              : "#0025408a",
          position:
            speaker === "Agent" || speaker === "Speaker1" ? "top" : "bottom",
        });

        return {
          start: elem.start,
          end: elem.end,
          color,
          loop: false,
          drag: false,
          resize: false,
        };
      });

      this.$nextTick(async () => {
        const wavesurfer = WaveSurfer.create({
          container: document.querySelector("#player"),
          waveColor: "rgb(0 150 254 / 55%)",
          progressColor: "rgb(0 150 254 / 80%)",
          cursorColor: "rgb(0 150 254)",
          splitChannels: false,
          cursorWidth: 1,
          height: 100,
          backend: "MediaElement",
          plugins: [
            RegionsPlugin.create({
              regions,
              dragSelection: {
                slop: 5,
              },
            }),
            MarkersPlugin.create({
              markers,
            }),
          ],
        });

        const observations = await this.getRegions();

        if (observations && observations.length > 0) {
          observations.forEach((elem) => {
            const regionObject = wavesurfer.addRegion({
              start: elem.start,
              end: elem.end,
              drag: true,
              resize: true,
            });
            this.player.regions.push({
              ...regionObject,
              comment: elem.comment,
            });
          });
        }

        wavesurfer.on("finish", () => {
          this.player.status = false;
        });

        wavesurfer.on("audioprocess", () => {
          this.currentTime = wavesurfer.getCurrentTime();
        });

        wavesurfer.on("waveform-ready", () => {
          this.loading = false;
        });

        wavesurfer.on("region-created", (region) => {
          this.player.regions.push({ ...region, comment: "" });
        });

        wavesurfer.on("region-out", (region) => {
          // check if region is overlaped with another region start or end and if it is, do not play the next region.
          if (!this.playnoblanks && !this.onlyPlayNegatives) {
            return;
          }
          let regionsArr = [];
          regionsArr = this.objectToArray(this.player.wavesurfer.regions.list);

          let overlaped = false;
          regionsArr.forEach((elem) => {
            if (elem.id !== region.id) {
              if (elem.start < region.end && elem.end > region.start) {
                overlaped = true;
              }
            }
          });
          if (overlaped) {
            return;
          }

          if (!this.onlyPlayNegatives) {
            const index = regionsArr.indexOf(region);
            if (index < regionsArr.length - 1) {
              this.player.wavesurfer.play(regionsArr[index + 1].start);
            } else {
              this.player.status = false;
            }
          } else {
            // here should look for the actual region and for the next region in the array that has color #e5393536
            const nextNegativeRegion = regionsArr.find(
              (elem) =>
                elem.id !== region.id &&
                elem.color === "#e5393536" &&
                elem.start > region.end
            );
            if (nextNegativeRegion) {
              this.player.wavesurfer.play(nextNegativeRegion.start);
            } else {
              this.player.status = false;
              this.player.wavesurfer.stop();
            }
          }
        });

        wavesurfer.on("region-updated", (region) => {
          if (region.drag) {
            this.player.regionClicked = region;
            const elem = this.player.regions.filter(
              (elem) => elem.id === region.id
            )[0];
            elem.start = region.start;
            elem.end = region.end;

            this.$nextTick(() => {
              this.$forceUpdate();
              // document.getElementById(region.id + '-comment').scrollIntoView({ behavior: 'smooth', block: 'center' });
              setTimeout(() => {
                document
                  .getElementById(region.id + "-comment")
                  .classList.remove("yellow");
                this.getSorted();
              }, 1000);
            });
          }
        });

        wavesurfer.on("region-click", (region) => {
          if (region.drag) {
            this.player.regionClicked = region;
            this.$nextTick(() => {
              this.$forceUpdate();
              document
                .getElementById(region.id + "-comment")
                .scrollIntoView({ behavior: "smooth", block: "nearest" });
              setTimeout(() => {
                document
                  .getElementById(region.id + "-comment")
                  .classList.remove("yellow");
              }, 1000);
            });
          }
        });

        wavesurfer.on("region-updated-end", () => {
          console.log("updated end");
        });

        this.player.wavesurfer = wavesurfer;
        const url = await this.getURL();
        this.file.url = url;
        this.player.wavesurfer.load(url);
      });
    } else this.$router.replace("/404");
  },
  computed: {
    filterRepeatedTopics() {
      const topics = this.file.result.data.topics;
      const filteredTopics = {};
      topics.forEach((elem) => {
        if (!filteredTopics[elem.topic]) {
          filteredTopics[elem.topic] = elem;
        } else {
          if (filteredTopics[elem.topic].confidence < elem.confidence) {
            filteredTopics[elem.topic] = elem;
          }
        }
      });
      const arr = Object.values(filteredTopics);

      return arr.sort((a, b) => b.confidence - a.confidence);
    },
    headers() {
      return [
        {
          text: this.$i18n.t("ASSESSMENT.tableHeaders.metricDescription"),
          value: "metricDescription",
        },
        {
          text: this.$i18n.t("ASSESSMENT.tableHeaders.metricValue"),
          value: "metricValue",
        },
      ];
    },
  },
};
</script>

<style scoped>
.v-timeline-item {
  padding-right: 10px;
}

.rowClick:hover {
  background: lightgray;
  cursor: pointer;
}

.activeWord {
  border-bottom: 1px solid red;
}

.keyword {
  color: gold !important;
}

.v-timeline-item:hover {
  cursor: pointer;
}
</style>
