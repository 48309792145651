<template>
  <v-row>
    <template v-if="type == 'voicesentiment'">
      <v-col align-self="center">
        <plotly
          v-if="showVoiceToneChart('agent')"
          :data="ratioVoiceChartDataSentiment('agent')"
          :layout="ratioVoiceChartLayout"
        />
        <p class="text-center" v-else>
          {{ $i18n.t("ASSESSMENT.noDataAgent") }}
        </p>
      </v-col>
      <v-col align-self="center">
        <plotly
          v-if="showVoiceToneChart('caller')"
          :data="ratioVoiceChartDataSentiment('caller')"
          :layout="ratioVoiceChartLayout"
          :display-mode-bar="false"
        />
        <p class="text-center" v-else>
          {{ $i18n.t("ASSESSMENT.noDataCaller") }}
        </p>
      </v-col>
    </template>
    <template v-else-if="type != 'sentiment'">
      <v-col v-if="type != 'talktime'">
        <plotly
          :data="chartData"
          :layout="chartLayout"
          :display-mode-bar="false"
        />
      </v-col>
      <v-col v-if="ratioMetrics.length > 0 && type == 'talktime'">
        <plotly
          :data="ratioChartData"
          :layout="ratioChartLayout"
          :display-mode-bar="false"
        />
      </v-col>
    </template>

    <template v-else>
      <v-col align-self="center">
        <plotly
          v-if="showSentimentChart('agent')"
          :data="ratioChartDataSentiment('agent')"
          :layout="ratioChartLayout"
          :display-mode-bar="false"
        />
        <p class="text-center" v-else>
          {{ $i18n.t("ASSESSMENT.noDataAgent") }}
        </p>
      </v-col>
      <v-col align-self="center">
        <plotly
          v-if="showSentimentChart('caller')"
          :data="ratioChartDataSentiment('caller')"
          :layout="ratioChartLayout"
          :display-mode-bar="false"
        />
        <p class="text-center" v-else>
          {{ $i18n.t("ASSESSMENT.noDataCaller") }}
        </p>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { Plotly } from "vue-plotly";

export default {
  name: "PlotMetrics",
  components: {
    Plotly,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: null,
    },
    metrics: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    ratioChartDataSentiment(usertype) {
      // const colors = ["#E53935", "#4CAF50", "#90A4AE"];
      let metrics = [];
      if (usertype === "agent") {
        metrics = this.$props.metrics.filter((metric) =>
          metric.metricName.includes("agent")
        );
      } else {
        metrics = this.$props.metrics.filter((metric) =>
          metric.metricName.includes("caller")
        );
      }
      metrics = metrics.filter((metric) => metric.metricName.includes("total"));

      metrics = JSON.parse(JSON.stringify(metrics));

      metrics.forEach((metric) => {
        if (metric.metricName.includes("positive")) {
          metric.metricColor = "#4CAF50";
        } else if (metric.metricName.includes("negative")) {
          metric.metricColor = "#f44336";
        } else if (metric.metricName.includes("neutral")) {
          metric.metricColor = "#00bcd4";
        }
      });

      metrics = metrics.sort((a, b) => {
        return a.metricValue - b.metricValue;
      });

      const data = [
        {
          values: metrics.map((metric) => metric.metricValue),
          labels: metrics.map((metric) => metric.metricDescription),
          type: "pie",
          marker: {
            colors: metrics.map((metric) => metric.metricColor),
          },
        },
      ];

      return data;
    },
    showSentimentChart(usertype) {
      let metrics = [];
      if (usertype === "agent") {
        metrics = this.$props.metrics.filter((metric) =>
          metric.metricName.includes("agent")
        );
      } else {
        metrics = this.$props.metrics.filter((metric) =>
          metric.metricName.includes("caller")
        );
      }
      metrics = metrics.filter((metric) => metric.metricName.includes("total"));
      if (metrics.length !== 0) {
        return true;
      } else return false;
    },
    ratioVoiceChartDataSentiment(usertype) {
      let metrics = [];
      if (usertype === "agent") {
        metrics = this.$props.metrics.filter(
          (metric) =>
            metric.metricName.includes("agent-positive") ||
            metric.metricName.includes("agent-negative") ||
            metric.metricName.includes("agent-neutral")
        );
      } else {
        metrics = this.$props.metrics.filter(
          (metric) =>
            metric.metricName.includes("caller-positive") ||
            metric.metricName.includes("caller-negative") ||
            metric.metricName.includes("caller-neutral")
        );
      }

      metrics = JSON.parse(JSON.stringify(metrics));

      metrics.forEach((metric) => {
        if (metric.metricName.includes("positive")) {
          metric.metricColor = "#4CAF50";
        } else if (metric.metricName.includes("negative")) {
          metric.metricColor = "#f44336";
        } else if (metric.metricName.includes("neutral")) {
          metric.metricColor = "#00bcd4";
        }
      });

      metrics = metrics.sort((a, b) => {
        return a.metricValue - b.metricValue;
      });
      const data = [
        {
          values: metrics.map((metric) => metric.metricValue),
          labels: metrics.map((metric) => metric.metricDescription),
          type: "pie",
          marker: {
            colors: metrics.map((metric) => metric.metricColor),
          },
        },
      ];
      return data;
    },
    showVoiceToneChart(usertype) {
      let metrics = [];
      if (usertype === "agent") {
        metrics = this.$props.metrics.filter(
          (metric) =>
            metric.metricName.includes("agent-positive") ||
            metric.metricName.includes("agent-negative") ||
            metric.metricName.includes("agent-neutral")
        );
      } else {
        metrics = this.$props.metrics.filter(
          (metric) =>
            metric.metricName.includes("caller-positive") ||
            metric.metricName.includes("caller-negative") ||
            metric.metricName.includes("caller-neutral")
        );
      }
      return Boolean(metrics.length);
    },
  },
  computed: {
    filteredMetrics() {
      return this.$props.metrics.filter(
        (metric) =>
          !metric.metricName.includes("ratio") &&
          !metric.metricName.includes("start") &&
          !metric.metricName.includes("end") &&
          !metric.metricName.includes("most")
      );
    },
    ratioMetrics() {
      return this.$props.metrics.filter(
        (metric) =>
          metric.metricName.includes("ratio") &&
          !metric.metricDescription.includes(" or")
      );
    },
    chartData() {
      const agentMetrics = this.filteredMetrics.filter((metric) =>
        metric.metricName.includes("agent")
      );
      const callerMetrics = this.filteredMetrics.filter((metric) =>
        metric.metricName.includes("caller")
      );
      const data = [
        {
          x: agentMetrics.map((metric) => metric.metricDescription),
          y: agentMetrics.map((metric) => metric.metricValue),
          type: "bar",
          name: "Agent",
          marker: {
            color: agentMetrics.map((metric) => {
              if (metric.metricName.includes("positive")) {
                return "#4CAF50";
              } else if (metric.metricName.includes("negative")) {
                return "#f44336";
              } else if (metric.metricName.includes("agent")) {
                return "#1976d2";
              } else {
                return "#00bcd4";
              }
            }),
          },
        },
        {
          x: callerMetrics.map((metric) => metric.metricDescription),
          y: callerMetrics.map((metric) => metric.metricValue),
          type: "bar",
          name: "Caller",
          marker: {
            color: callerMetrics.map((metric) => {
              if (metric.metricName.includes("positive")) {
                return "#4CAF50";
              } else if (metric.metricName.includes("negative")) {
                return "#f44336";
              } else if (metric.metricName.includes("agent")) {
                return "#1976d2";
              } else {
                return "#00bcd4";
              }
            }),
          },
        },
      ];

      return data;
    },
    ratioChartData() {
      const colors = ["#1976d2", "#00bcd4", "#2196f3"];

      const data = [
        {
          values: this.ratioMetrics.map((metric) => metric.metricValue),
          labels: this.ratioMetrics.map((metric) => metric.metricDescription),
          type: "pie",
          marker: {
            colors: colors.slice(0, this.ratioMetrics.length),
          },
        },
      ];

      return data;
    },
    chartLayout() {
      const title =
        this.$props.type === "overtalk"
          ? this.$i18n.t("ASSESSMENT.overtalk")
          : this.$i18n.t("ASSESSMENT.wordCount");

      return {
        height: 400,
        title,
        yaxis: {
          title: "Value",
          automargin: true,
        },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
      };
    },
    ratioChartLayout() {
      let title = this.$i18n.t("ASSESSMENT.talkRatio");
      switch (this.$props.type) {
        case "overtalk":
          title = this.$i18n.t("ASSESSMENT.overtalkRadio");
          break;
        case "word":
          title = this.$i18n.t("ASSESSMENT.wordRatio");
          break;
        case "voicesentiment":
          title = this.$i18n.t("ASSESSMENT.voiceSentimentRatio");
          break;
        case "sentiment":
          title = this.$i18n.t("ASSESSMENT.sentimentRatio");
          break;
      }

      return {
        title,
        height: 400,
        margin: {
          b: 0, // bottom margin
        },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
      };
    },
    ratioVoiceChartLayout() {
      let title = this.$i18n.t("ASSESSMENT.talkRatio");
      switch (this.$props.type) {
        case "overtalk":
          title = this.$i18n.t("ASSESSMENT.overtalkRadio");
          break;
        case "word":
          title = this.$i18n.t("ASSESSMENT.wordRatio");
          break;
        case "voicesentiment":
          title = this.$i18n.t("ASSESSMENT.voiceSentimentRatio");
          break;
        case "sentiment":
          title = this.$i18n.t("ASSESSMENT.sentimentRatio");
          break;
      }

      return {
        title,
        height: 400,
        margin: {
          b: 0, // bottom margin
        },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
      };
    },
  },
};
</script>

<style scoped></style>
