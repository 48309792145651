exports.returnCredibleKeywords = (keywords) => {
  if (!keywords || keywords.length === 0) return [];

  return keywords
    .map((keyword) => {
      return {
        isCredible: keyword.hits.some((hit) => hit.confidence >= 0.8),
        query: keyword.query,
      };
    })
    .filter((credibleKeyword) => credibleKeyword.isCredible);
};
